import {onLoad, els, validateEmail} from './util/util';

onLoad(() => {
    try {
        const inputContainers = els('.jls-input');
        for (const i of inputContainers) {
            const inputField = i.querySelector('input');
            const textareaField = i.querySelector('textarea');
            const requiredError = i.querySelector('.error-container.required');

            if (inputField) {
                //check on first load if there is content
                onLoadCheckIfContent(inputField);
                inputField.addEventListener("focus", e => onFocus(inputField));
                inputField.addEventListener("focusout", e => onFocusOut(inputField, requiredError));
                inputField.addEventListener("input", e => handleOnInput(inputField, requiredError));
            }
            if (textareaField) {
                //check on first load if there is content
                onLoadCheckIfContent(textareaField);
                textareaField.addEventListener("focus", e => onFocus(textareaField, requiredError));
                textareaField.addEventListener("focusout", e => onFocusOut(textareaField, requiredError));
                textareaField.addEventListener("input", e => handleOnInput(textareaField, requiredError));
            }
        }
    } catch (err) {
        console.error('Error initializing inputfields', err);
    }
});

function onFocusOut(item, requiredError) {
    const label = item.previousSibling.previousSibling;
    handleInputErrorOnFocusOut(item, label, requiredError);
    if (!item.value && label) {
        item.previousSibling.previousSibling.classList.remove('move-up');
    }
}

function onFocus(item) {
    const label = item.previousSibling.previousSibling;
    if (item.value) {
        label.classList.remove('label-error');
    }
    if (label) {
        item.previousSibling.previousSibling.classList.add('move-up');
    }
}

function onLoadCheckIfContent(item) {
    const label = item.previousSibling.previousSibling;
    if (label && item.value) {
        item.previousSibling.previousSibling.classList.add('move-up');
    }
}

export function handleInputErrorOnFocusOut(item, label, requiredError) {
    if (!isItemValid(item)) {
        label.classList.add('label-error');
        item.classList.add('input-error');
        requiredError.classList.remove('hidden');
    } else {
        label.classList.remove('label-error');
        item.classList.remove('input-error');
        requiredError.classList.add('hidden');
    }
}

function handleOnInput(item, requiredError) {
    const label = item.previousSibling.previousSibling;

    if (isItemValid(item)) {
        label.classList.remove('label-error');
        item.classList.remove('input-error');
        requiredError.classList.add('hidden');
    }
}

export function isItemValid(item) {
    if (item.id === "email" && !validateEmail(item.value)) {
        return false;
    }
    if (item.id === "message" && item.value.toString().trim().length < 5) {
        return false;
    }
    if (item.required && !item.value) {
        return false;
    }
    return true;
}


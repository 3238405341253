import {onLoad, el, els, selectElementsFromParent, selectElementFromParent, validateEmail} from './util/util';
import {contactsAxiosInstance, friendlyAxiosInstance, googleAxiosInstance} from './util/httpInstances';
import {handleInputErrorOnFocusOut, isItemValid} from './input-fields';
import {handleCheckboxErrorOnSubmit} from './checkbox-with-text';
import {friendlyCaptchaGotSolution, friendlyCaptchaUnstarted} from './util/friendlyCaptcha';

onLoad(() => {
    try {
        const forms = els('form');
        forms.forEach((form) => {
            const inputErrorContainers = selectElementsFromParent(form, '.input-error-container');
            Array.from(inputErrorContainers).forEach((inputErrorContainer) => {
                const inputContainer = selectElementFromParent(inputErrorContainer, '.input-container');
                const errorRequiredContainer = selectElementFromParent(inputErrorContainer, '.error-container.required');
                const errorEmailContainer = selectElementFromParent(inputErrorContainer, '.error-container.email');
                let inputField = selectElementFromParent(inputContainer, 'input');
                if (!inputField) {
                    inputField = selectElementFromParent(inputContainer, 'textarea');
                }

                if (inputField.required) {
                    errorRequiredContainer.innerHTML = formInputFieldsMap
                        .get(form.id)
                        .inputFields.get(inputField.id).requiredErrorMessage;
                }
            });

            form.addEventListener('submit', async (event) => {
                // prevent default form behavior
                event.preventDefault();

                // if no errors in the input fields Form can be sent
                const inputFields = formInputFieldsMap.get(event.target.id).inputFields;

                inputFields.forEach((value, key) => {
                    const input = event.target[key];
                    value.hasError = !(isItemValid(input));
                    value.value = input.value;
                    const inputErrorContainer = input.parentElement.parentElement;
                    const requiredError = inputErrorContainer.querySelector('.error-container.required');

                    if (value.hasError) {
                        const label = input.previousSibling.previousSibling;
                        handleInputErrorOnFocusOut(input, label, requiredError);
                    }
                });

                const allInputFieldsValid = Array.from(inputFields.values()).every((value) => !value.hasError);

                // check that all required checkboxes are ticked
                formConsentMap.get(event.target.id).forEach((value, key) => {
                    const checkBoxInputElement = event.target[key];
                    value.hasError = value.isRequiredToSubmitForm ? !value.isChecked : false;

                    if (value.hasError) {
                        handleCheckboxErrorOnSubmit(checkBoxInputElement);
                    }
                });

                const allConsentCheckmarksSet = Array
                    .from(formConsentMap.get(form.id).values())
                    .every((consentItem) => !consentItem.hasError);

                const friendlyCaptchaSolutionElement = selectElementFromParent(form, '.frc-captcha-solution');
                const friendlyCaptchaContainer = selectElementFromParent(form, '.fc-form-input');
                const friendlyCaptchaElement = selectElementFromParent(friendlyCaptchaContainer, '.frc-captcha');
                const friendlyCaptchaHasSolution = friendlyCaptchaGotSolution(friendlyCaptchaSolutionElement.value);

                setupFriendlyCaptchaError(friendlyCaptchaContainer, friendlyCaptchaHasSolution, friendlyCaptchaSolutionElement)

                if (allInputFieldsValid && allConsentCheckmarksSet) {

                    // validate google recaptcha
                    const recaptchaInputField = el('#recaptcha-v3');
                    if (recaptchaInputField) {
                        const isBot = await checkRecaptcha(recaptchaInputField);
                        // check if recaptcha passes
                        if (isBot) {
                            console.error('Bot detected!');
                            return false;
                        }
                    }

                    // validate Friendly Captcha
                    if (friendlyCaptchaContainer && friendlyCaptchaHasSolution) {
                        const captchaPassed = await checkFriendlyCaptcha(friendlyCaptchaSolutionElement, friendlyCaptchaElement);
                        // check if friendCaptcha passes
                        if (!captchaPassed) {
                            console.error('Bot detected!');
                            return false;
                        }
                    } else {
                        // if it doesn't have solution
                        return false;
                    }
                    // createContact POST call for both newsletter signup and regular contact form
                    const formPurpose = formPurposeMap.get(form.id).formPurpose;
                    const bodyRequest = {
                        properties: Object.fromEntries(Array.from(inputFields, ([k, v]) => [k, v.value])),
                    };
                    const response = await createContact(bodyRequest);

                    if (formPurpose === 'contact') {
                        if (response.status === 200) {
                            forwardToSuccessWindow();
                        } else if (response.status === 409) {
                            // contact with this email already exists, thus we do not create a new contact, but update the existing one
                            await updateContact(bodyRequest);
                            forwardToSuccessWindow();
                        }
                    } else if (formPurpose === 'newsletter') {
                        if (response.status === 200 || response.status === 409) {
                            // in case of newsletter signup, add contact to newsletter list regardless if contact is fresh or already existed
                            const addToListRequestBody = {
                                'emails': [`${bodyRequest.properties.email}`],
                            };
                            const addToListResponse = await addContactToList(addToListRequestBody, formPurposeMap.get(form.id).contactListId);

                            if (addToListResponse.status === 200) {
                                forwardToSuccessWindow();
                            }
                        }
                    } else {
                        console.warn('unable to recognize form usecase');
                        // default to forward to success page anyway
                        if (response.status === 200) {
                            forwardToSuccessWindow();
                        }
                    }
                }
                return false;
            });
        });
    } catch (err) {
        console.error('Error initializing or validating form', err);
    }
});

function setupFriendlyCaptchaError(friendlyCaptchaContainer, friendlyCaptchaHasSolution, friendlyCaptchaSolutionElement) {
    // set friendly captcha callback
    // https://docs.friendlycaptcha.com/#/widget_api?id=if-you-are-using-the-widget-script-tag
    window.friendlyChallenge.autoWidget.opts.doneCallback = removeFriendlyCaptchaError;

    const friendlyCaptchaError = selectElementFromParent(friendlyCaptchaContainer, '.error-container');
    if (friendlyCaptchaContainer && !friendlyCaptchaHasSolution) {
        if (friendlyCaptchaUnstarted(friendlyCaptchaSolutionElement.value)) {
            friendlyCaptchaError.classList.remove("hidden");
        }
    }
}

function removeFriendlyCaptchaError() {
    document.querySelector(".fc-error-container").classList.add("hidden");
}

function forwardToSuccessWindow() {
    const {
        origin,
        pathname,
    } = window.document.location;
    const successPageRelUrl = `${pathname.replace('.html', '')}/success`;
    window.location.href = origin + successPageRelUrl;
}

async function createContact(bodyRequest) {
    try {
        return await contactsAxiosInstance.post('/', bodyRequest);
    } catch (e) {
        if (e.response.status === 409) {
            // contact already exists
            return e.response;
        } else {
            console.error(e);
            return null;
        }
    }
}

async function updateContact(bodyRequest) {
    // we do not want to override possible set values with non-set values of contact form, thus we strip them
    Object.keys(bodyRequest.properties)
        .filter(key => !bodyRequest.properties[key])
        .forEach(key => delete bodyRequest.properties[key]);
    try {
        return await contactsAxiosInstance.post('/update', bodyRequest);
    } catch (e) {
        if (e.response.status === 404) {
            // contact not found
            return e.response;
        } else {
            console.error(e);
            return null;
        }
    }
}

async function addContactToList(bodyRequest, listId) {
    try {
        return await contactsAxiosInstance.post(`/lists/${listId}/add`, bodyRequest);
    } catch (e) {
        console.error(e);
        return null;
    }
}

async function checkRecaptcha(inputField) {
    const {hostname} = window.document.location;
    if (hostname === 'localhost') {
        return false;
    }
    try {
        const bodyRequest = {
            response: inputField.value,
        };
        const response = await googleAxiosInstance.post('/recaptcha', bodyRequest);
        return response.data;
    } catch (e) {
        console.error('error in during checkRecaptcha', e);
        return true;
    }
}

async function checkFriendlyCaptcha(friendlyCaptchaSolutionElement, friendlyCaptchaElement) {
    try {
        const bodyRequest = {
            solution: friendlyCaptchaSolutionElement.value,
            siteKey: friendlyCaptchaElement.dataset.sitekey,
        };
        const response = await friendlyAxiosInstance.post('/verify', bodyRequest);
        return response.data;
    } catch (e) {
        console.error('error in during checkFriendlyCaptcha', e);
        return true;
    }
}

import {gsap} from "gsap";
import {animateOfferImages} from "./offer-image";
import {animateReferenceCircleOnImage} from "./reference-circle-image";
import {animateToggleList} from "./toggle-list";
import {animateTexts} from "./text";
import {animateTeaserCoverFlow} from "./teaser-component";
import {animateKpis} from "./kpi-counter";
import {animateTiles} from "./tile";
import {animateScrollToServices} from "./scroll-to-services";
import {animateScrollToSelectedOffer} from "./scroll-to-selected-offer";
import {onLoad} from "../util/util";

//wait until DOM is ready
onLoad(() => {
    //wait until window is loaded (media assets)
    window.onload = () => {
        //make content visible, prevent Flash-of-Unstyled-Content (FOUC)
        const allSections = document.querySelectorAll(".fouc");
        allSections.forEach((section) => {
            if (getComputedStyle(section).display !== "block") {
                section.style.display = "block";
            }
            gsap.from(section, {duration: 0.01, autoAlpha: 0});
        });

        //adjust pixel ratio if necessary
        const screenSizeToggle = document.querySelector(".screen-size-desktop-toggle");
        if (screenSizeToggle && getComputedStyle(screenSizeToggle).display === "block") {
            document
                .querySelector("meta[name=viewport]")
                .setAttribute("content", "width=device-width, initial-scale=" + 1 / window.devicePixelRatio);
        }

        //All Pages: Text Animation
        animateTexts();

        //Offer Page: Offer Animation
        animateOfferImages();

        //Offer Page: if navigated to with anchor pointing at specific offer; scroll down to first visible (will be the selected one, logic for that present in offer app)
        animateScrollToSelectedOffer();

        //Reference Page: Circle Animation
        animateReferenceCircleOnImage();

        //Reference Tiles Component: Filter


        //Default Footer (mobile) + Generically usable: Toggle-List
        animateToggleList();

        //Teaser Component: Slider Animation
        animateTeaserCoverFlow();

        //KPI Component: Initiate Counter
        animateKpis();

        //Tiles: Hover Animation
        animateTiles();

        //Service Component: Scroll-To Behavior per Click on Anchor
        animateScrollToServices();
    };
});

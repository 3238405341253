export const el = (selector) => {
    return document.querySelector(selector);
};

export const els = (selector) => {
    return document.querySelectorAll(selector);
};

export const selectElementFromParent = (parent, query) => {
    if (parent) {
        return parent.querySelector(query);
    }
};

export const selectElementsFromParent = (parent, query) => parent.querySelectorAll(query);

export const selectClosestMatchingParentNode = (child, query) => child.closest(query);

export const onLoad = (fn) => document.addEventListener("DOMContentLoaded", fn);

export const validateEmail = (mail) => {
    return (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(mail);
}


export const PHABLET_BREAKPOINT = 768;
